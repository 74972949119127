import PropTypes from 'prop-types';
import { Box, IconButton, Typography, Divider, Collapse } from '@material-ui/core';
import ChevronDownIcon from '../../../icons/ChevronDown';
import ChevronUpIcon from '../../../icons/ChevronUp';
import { useState } from 'react';

const ActionPlanDrawerQualify = (props) => {
  const { actionPlanObj } = props;
  const trainings = actionPlanObj?.qualify?.trainings.length || 0;
  const professionA = actionPlanObj?.qualify?.occupationTitleA;
  const professionB = actionPlanObj?.qualify?.occupationTitleB;
  const contextId = actionPlanObj?.qualify?.qualifyContextId;
  const contextText = actionPlanObj?.qualify?.qualifyContextText;
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'stretch', justifyContent: 'space-between', py: 2 }}
      >
        <Box
          sx={{ height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Typography
            variant="h4"
            component="p"
          >
            {trainings}
          </Typography>
        </Box>
        <Box
          sx={{ width: 145 }}
        >
          <Typography sx={{ fontSize: '1.2rem', fontWeight: '600', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            Simulador de Carreira
          </Typography>
          <Typography sx={{ fontSize: '0.7rem', color: '#8D99AE' }}>
            {trainings}
            &nbsp;Formações
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {trainings > 0 && (
            <IconButton
              size="small"
              onClick={handleExpandClick}
            >
              {expanded ? <ChevronUpIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" /> }
            </IconButton>
          )}
        </Box>
      </Box>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <Divider sx={{ mb: 1 }} />
        <Box>
          <Typography sx={{ fontWeight: 600 }}>Contexto</Typography>
          {contextText && (
            <Typography
              variant="body2"
              pl={1}
            >
              {contextText}
            </Typography>
          )}
          <Typography sx={{ fontWeight: 600 }}>Profissão</Typography>
          {contextId === 1 && (
            <Typography
              variant="body2"
              pl={1}
            >
              {professionB}
            </Typography>
          )}
          {contextId === 2 && (
            <Typography
              variant="body2"
              pl={1}
            >
              {professionA}
            </Typography>
          )}
          {contextId === 3 && (
            <Typography
              variant="body2"
              pl={1}
            >
              {professionA}
              &nbsp;&gt;&nbsp;
              {professionB}
            </Typography>
          )}
        </Box>
        <Typography sx={{ fontWeight: 600 }}>Formações</Typography>
        {actionPlanObj?.qualify?.trainings && actionPlanObj.qualify.trainings.map((m) => (
          <Typography
            variant="body2"
            pl={1}
            key={`${m.name}_${m.url}`}
          >
            &bull;&nbsp;
            {m.name}
          </Typography>
        ))}
        <Divider sx={{ mt: 1 }} />
      </Collapse>
    </>
  );
};

ActionPlanDrawerQualify.propTypes = {
  actionPlanObj: PropTypes.object
};

export default ActionPlanDrawerQualify;
