import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import BrainsIcon from '../../icons/myMentor/Brains';
import CompassIcon from '../../icons/myMentor/Compass';
import RocketIcon from '../../icons/myMentor/Rocket';
import AcademicBoardIcon from '../../icons/myMentor/AcademicBoard';
import HandLight from '../../icons/myMentor/HandLight';
import SearchJob from '../../icons/myMentor/SearchJob';
import Handshake from '../../icons/myMentor/Handshake';
import Map from '../../icons/myMentor/Map';

const sections = [
  {
    id: '1',
    items: [
      {
        title: 'Conhecer-me',
        path: '/conhecer-me',
        icon: <BrainsIcon />
      }
    ]
  },
  {
    id: '2',
    items: [
      {
        title: 'Explorar',
        path: '/explorar',
        icon: <CompassIcon />,
        children: [
          {
            title: 'Profissões',
            path: '/explorar/profissoes'
          },
          {
            title: 'Competências',
            path: '/explorar/competencias'
          },
          {
            title: 'Formações',
            path: '/explorar/formacoes'
          }
        ]
      }
    ]
  },
  {
    id: '3',
    items: [
      {
        title: 'Simulador de Carreira',
        path: '/simulador-de-carreira',
        icon: <AcademicBoardIcon />
      }
    ]
  },
  {
    id: '4',
    items: [
      {
        title: 'Preparar-me',
        path: '/preparar-me',
        icon: <RocketIcon />,
        children: [
          {
            title: 'Mercado de Trabalho',
            path: '/preparar-me/mercado-trabalho'
          },
          {
            title: 'Casos de Sucesso',
            path: '/preparar-me/casos-sucesso'
          }
        ]
      }
    ]
  },
  {
    id: '5',
    items: [
      {
        title: 'Oportunidades de Emprego',
        path: '/oportunidades-emprego',
        icon: <SearchJob />
      }
    ]
  },
  {
    id: '7',
    items: [
      {
        title: 'Empreender',
        path: '/empreender',
        icon: <HandLight />
      }
    ]
  },
  {
    id: '8',
    items: [
      {
        title: 'Preciso de Apoio',
        path: '/preciso-apoio',
        icon: <Handshake />
      }
    ]
  }
];

const SideMenuSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  if (process.env.REACT_APP_JOBMAP_TOGGLE.toLowerCase() === 'true' && !sections.find((x) => x.id === '6')) {
    sections.push({
      id: '6',
      items: [
        {
          title: 'Mapa de Emprego',
          path: '/mapa-emprego/tendencias',
          icon: <Map />
        }
      ]
    });
    sections.sort((a, b) => a.id - b.id);
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              px: 2
            }}
          >
            <Box
              component={RouterLink}
              to="/"
            >
              <Logo
                sx={{
                  height: 30,
                  maxWidth: 155
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.id}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 1
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: '100% !important',
            width: 220
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

SideMenuSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default SideMenuSidebar;
